@charset 'UTF-8';

// Disable some linters as print styles need to be more restrictive on some places
// scss-lint:disable ImportantRule, IdSelector

// Use this file to define print styles.
// Inspired by HTML5 Boilerplate: https://github.com/h5bp/html5-boilerplate
//
// Import this file using the following HTML or equivalent:
// <link href="/stylesheets/print.css" media="print">


$black: #000;
$grey: #999;

.show-for-print { display: none !important; }

// ///////////////////////////////////////////
// custom print styles
@media print {
    .show-for-print { display: block !important; }
    .hide-for-print { display: none !important; }

    // -------------------------------------
    // style print pages itself
    @page {
        size: A4 portrait;
        margin: 3em;
    }

    // -------------------------------------
    // html/body resets
    html,
    body {
        height: auto !important;
    }

    // -------------------------------------
    // prevent tags messing up page breaks
    * {
        position: static !important;
        float: none !important;
    }

    .off-canvas {
        height: auto !important;
        min-height: 0 !important;
    }

    div {
        display: block !important;
    }

    // -------------------------------------
    // strip as many colors as possible
    *,
    *::before,
    *::after {
        box-shadow: none !important;
        background: transparent !important;
        text-shadow: none !important;
        color: $black !important; // black prints faster: http://www.sanbeiji.com/archives/953
    }

    // some basic coloring
    pre,
    blockquote {
        border: 1px solid $grey;
    }

    // -------------------------------------
    // links

    a[target='_blank']::after {
        content: ' (' attr(href) ')';
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    // don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^="#"]::after,
    a[href^="javascript:"]::after {
        content: '';
    }

    // -------------------------------------
    // tables
    table,
    th,
    td {
        border: 1px solid $grey;
        border-collapse: collapse;
    }

    table {
        width: 100%;
        table-layout: fixed;

        td:empty {
            width: 30px;
        }

        ul {
            padding-left: 1em !important;

            li {
                word-wrap: break-word !important;
            }
        }
    }

    thead {
        display: table-header-group;
    }


    td {
        word-wrap: break-word;
    }

    // -------------------------------------
    // page breaks
    tr,
    img,
    pre,
    blockquote {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // -------------------------------------
    // footer styles
    .footer__wrapper {
        margin: 0 auto !important;
    }

    // -------------------------------------
    // reduce size and spacing
    img {
        margin: 0 !important;
    }

    .gallery__item {
        float: left !important;
    }

    .row,
    .boxes__column {
        padding-top: 1.5em !important;

        &:first-child,
        div.content-band--table {
            padding-top: 0 !important;
        }

        &.gallery {
            padding-top: 0 !important;
        }
    }

    // -------------------------------------
    // HIDE ALL THE THINGS
    //
    script,
    style,
    title,
    *:not(a)::after,
    *::before,
    .browser-alert, // browser alert boxes
    div.pswp, // PhotoSwipe container
    #wpadminbar, // WordPress adminbar
    .header-spacing,
    .menu-toggle,
    .off-canvas,
    .scroll-up,
    .logo::after,
    .icon--search,
    .anchor-spacer,
    .content-band__intro--media--video,
    .sub-nav {
        display: none !important;
    }
}
